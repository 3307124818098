import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"
import CookieConsent from "react-cookie-consent"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <CookieConsent
          location="bottom"
          buttonText="Verstanden!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "$orange" }}
          buttonStyle={{
            color: "$brown",
            fontFamily: "$font-family-sans-serif",
            fontSize: "13px",
          }}
          expires={150}
        >
          Diese Webseite verwendet nur technisch notwendige Cookies.{" "}
        </CookieConsent>
        <Header />

        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">
                  Familienunternehmen mit Tradition
                </h2>
                <hr className="divider light my-4" />
                <p className="text-white-50 mb-4">
                  Der Zelteverleih Alois Markett GbR ist ein Unternehmen mit
                  langjähriger Erfahrung und Tradition. Das Unternehmen wird
                  mittlerweile seit über 50 Jahren in 2. Generation geführt,
                  auch wenn Unternehmensgründer Alois Markett immer noch mit dem
                  gleichen Elan und dem gleichen Ehrgeiz bei der Sache ist, wie
                  am ersten Tag! Wir haben viele Trends kommen und gehen sehen.
                  Aber was die Menschen immer wieder begeistert, sind die
                  Momente, in denen Anlass und Ambiente zu einem positiven
                  Ganzen verschmelzen. Nach Ihren Wünschen und mit unserer
                  Erfahrung gestalten wir diese ganz besonderen Momente.
                </p>
                <a
                  className="btn btn-light btn-xl js-scroll-trigger"
                  href="#services"
                  onClick={Scroller.handleAnchorScroll}
                >
                  Das können wir bieten!
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="werte">
          <div className="container">
            <h2 className="text-center mt-0">Dafür stehen wir</h2>
            <hr className="divider my-4" />
            <div className="row">
              <div className="col-lg col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-balance-scale text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Zuverlässigkeit</h3>
                  <p className="text-muted mb-0">
                    Mit jahrelanger Erfahrung, Herz und Qualität setzen wir Ihre
                    Vision um. Sie können sich auf uns verlassen.
                  </p>
                </div>
              </div>
              <div className="col-lg col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-crown text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Kundenzentrierung</h3>
                  <p className="text-muted mb-0">
                    Zu unseren Kunden gehören seit vielen Jahren auch die ganz
                    großen Unternehmen aus der Region - sie wissen, was sie an
                    uns haben &amp; wir an Ihnen!
                  </p>
                </div>
              </div>
              <div className="col-lg col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-heart text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Herz &amp; Kreativität</h3>
                  <p className="text-muted mb-0">
                    Mit guten Ideen, Tatendrang und Herz stehen wir Ihnen in der
                    Planungs-, wie auch Umsetzungsphase stets zur Seite.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio">
          <div className="container-fluid pb-5">
            <div className="row no-gutters justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/1.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 0)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[0].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Partyzelte
                    </div>
                    <div className="project-name">
                      Unsere Partyzelte beginnen bei einer Grundfläche von 4 x 8
                      Metern. Diese können Sie durch Anbau weiterer Partyzelte
                      auf bis zu 18 x 12 Meter, bzw. 6 x 24 Meter erweitern.
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/2.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 1)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[1].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Festzelte
                    </div>
                    <div className="project-name">
                      Unsere Festzelte haben eine Grundfläche von 8 x 8 Metern.
                      Diese können Sie durch Anbau weiterer Festzelte auf bis zu
                      8 x 32 Meter, bzw. 16 x 32 Meter erweitern.
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/2.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 1)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[2].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Festzelte
                    </div>
                    <div className="project-name">
                      Unsere Festzelte haben eine Grundfläche von 8 x 8 Metern.
                      Diese können Sie durch Anbau weiterer Festzelte auf bis zu
                      8 x 32 Meter, bzw. 16 x 32 Meter erweitern.
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/3.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 2)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[3].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Zubehör
                    </div>
                    <div className="project-name">
                      Bei der richtigen Beleuchtung angefangen, über die
                      Sitzgelegenheiten und die Heizung bis hin zum
                      Toilettenwagen
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-primary" id="services">
          <div className="container p-2 ">
            <div className="">
              <h2 className="text-white text-center">
                Konfigurations-
                <br />
                möglichkeiten
              </h2>
              <hr className="divider light my-4 justify-content-center" />
              <div className="row">
                <div className="col-md-6">
                  <div className="backgroundxy">
                    <h3 className="text-white my-4 text-center">Partyzelte</h3>
                  </div>

                  <p className="px-2">
                    <small>
                      Markett Partyzelte schaffen durch die großflächigen
                      Fensteröffnungen in den Wänden aus Zeltplane
                      lichtdurchflutete Zeltinnenräume.
                      <br />
                      Partyzelte sind in folgenden Größen und Zusammenstellungen
                      erhältlich: <br />
                      <br />{" "}
                    </small>
                  </p>

                  <div className="d-block justify-content-center">
                    <div className="d-flex">
                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinszwei"></div>
                        <p className="pl-2">6x12 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinszwei"></div>
                        <div className="diveinszwei"></div>
                        <p className="pl-2">12x12 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinszwei"></div>
                        <div className="diveinszwei"></div>
                        <div className="diveinszwei"></div>
                        <p className="pl-2">12x18 m</p>
                      </div>
                    </div>

                    <br />

                    <div className="d-flex">
                      <div className="p-2 d-flex col-md-4">
                        <div className="d-block">
                          <div className="diveinszwei"></div>
                          <div className="diveinszwei"></div>
                        </div>
                        <p className="pl-2 ">6x24 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveins"></div>
                        <p className="pl-2">4x8 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinszwei"></div>
                        <div className="diveins"></div>
                        <p className="pl-2">
                          Anbauzelt:
                          <br /> 4x8 m
                        </p>
                      </div>
                    </div>

                    <br />

                    <p className="px-2">
                      <small>
                        Jedes Partyzelt kann durch ein baugleiches Anbauzelt
                        ergänzt werden (z.B. für Buffet, Theke, o.ä.){" "}
                      </small>
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="backgroundxy">
                    <h3 className="text-white my-4 text-center">Festzelte</h3>
                  </div>

                  <p className="px-2">
                    <small>
                      Markett Festzelte sind mit Holzwänden ausgestattet und in
                      folgenden Größen und Zusammenstellungen erhältlich:{" "}
                    </small>
                  </p>

                  <div className="d-block justify-content-center">
                    <div className="d-flex">
                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinseins"></div>
                        <p className="pl-2">8x8 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinseins"></div>
                        <div className="diveinseins"></div>
                        <p className="pl-2">8x16 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinseins"></div>
                        <div className="diveinseins"></div>
                        <div className="diveinseins"></div>
                        <p className="pl-2">8x24 m</p>
                      </div>
                    </div>

                    <br />

                    <div className="d-flex">
                      <div className="p-2 d-flex col-md-4">
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <p className="pl-2 ">16x16 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <p className="pl-2">16x24 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-4">
                        <div className="diveinseins"></div>
                        <div className="diveins"></div>
                        <p className="pl-2">
                          Anbauzelt:
                          <br /> 4x8 m
                        </p>
                      </div>
                    </div>

                    <br />

                    <div className="d-flex">
                      <div className="p-2 d-flex col-md-6">
                        <div className="diveinseins"></div>
                        <div className="diveinseins"></div>
                        <div className="diveinseins"></div>
                        <div className="diveinseins"></div>
                        <p className="pl-2 ">8x32 m</p>
                      </div>

                      <div className="p-2 d-flex col-md-6">
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <div className="d-block">
                          <div className="diveinseins"></div>
                          <div className="diveinseins"></div>
                        </div>
                        <p className="pl-2">16x32 m</p>
                      </div>
                    </div>

                    <p className="px-2">
                      <small>
                        Jedes Festzelt kann durch ein baugleiches Anbauzelt
                        ergänzt werden (z.B. für Buffet, Theke, o.ä.){" "}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="backgroundxy">
              <h4 className="text-white my-4 text-center">Zubehör</h4>
            </div>
            <p className="text-center">
              Des Weiteren können wir Ihnen die folgende Zeltausstattung
              anbieten:
              <br /> <br />
              <ul className="list-unstyled">
                <small>
                  <li>Toilettenwagen</li>
                  <li>Heizung</li>
                  <li>Stühle</li>
                  <li>Polsterstühle</li>
                  <li>Tische</li>
                  <li>Stehtische</li>
                  <li>Sitzgarnituren</li>
                </small>
              </ul>
            </p>
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Wir freuen uns auf Sie!</h2>
                <hr className="divider my-4" />
                <p className="text-muted mb-5">
                  Ob Hochzeit, Geschäftstreffen, Gartenfest oder Vereinsfeier -
                  wenn es um Zeltverleih geht, sind wir Ihr zuverlässiger
                  Ansprechpartner.
                  <br />
                  Machen Sie sich von den Launen des Wetters unabhängig - Wir
                  bieten Ihnen Zelte in verschiedenen Größen für nahezu jeden
                  Anlaß zu fairen Konditionen an! <br />
                  Natürlich bieten wir Ihnen auch ein reichhaltiges
                  Zubehörprogramm: Bei der richtigen Beleuchtung angefangen,
                  über die Sitzgelegenheiten und die Heizung bis hin zum
                  Toilettenwagen. Lassen Sie sich von uns unverbindlich Ihr
                  persönliches Angebot erstellen! <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 mr-auto text-center">
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <a className="d-block" href="mailto:info@markett-zelte.de">
                  info@markett-zelte.de
                </a>
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-3 mr-auto text-center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9901.082864710006!2d6.619486704933178!3d51.65487967614634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b89aa55875d3a5%3A0xc242b3570f7a1042!2sAlois%20Markett%20GbR%20Zeltverleih!5e0!3m2!1sde!2sde!4v1585695283083!5m2!1sde!2sde"
                  width="400"
                  height="300"
                  frameBorder="0"
                  style={{ border: "0" }}
                  allowFullscreen=""
                  aria-hidden="false"
                  title="GoogleMaps"
                ></iframe>
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-3 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-phone fa-3x mb-3 text-muted"></i> <br />
                <p>
                  {" "}
                  <a href="tel:02815854">+49 (0281) 58 54</a>{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <PortfolioModal
          show={this.state.modalShow}
          onHide={() => this.setModal(false, 0)}
        >
          <PortfolioCarousel
            images={this.props.data.images.edges}
            current={this.state.modalCurrent}
          />
        </PortfolioModal>
      </Layout>
    )
  }
}

export const imageData = graphql`
  query {
    images: allFile(
      filter: { relativePath: { glob: "portfolio/fullsize/*.jpg" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
